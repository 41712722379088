import React from "react"
import Layout from "../../components/layout/Layout.js"
import { Row, BlogRow } from "../../components/row/Row.js"
import {
  Title, 
  BlogTitle,
  SubHeading,
} from "../../components/title/Title.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/content-strategy-document.png"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { TextMedium, TextSmall } from "../../components/text/Text.js"
import { Contact, ButtonContainer } from "../../components/button/Button.js"


function ContentStrategyDocument({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="Content Strategy Document - Template" 
       description="This template includes the main areas that should be included in a content strategy document."
       image={img1}
       featuredImage={img1} />

      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/content-strategy-document-template/">Content Strategy - Template</a>
            </li>
          </Breadcrumb>
        </Row>
        <Row isCentered>
          <Column50>
            <BlogTitle isPrimary title="Content Strategy Document - Template" />
            <SubHeading subheading="This template includes the main areas that should be included in a content strategy document." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/partner-marketing-strategy-template" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="Content Strategy Document Template" />
          </Column50>
        </Row>
      </AboveFold>

      <Section>
        <BlogRow isCentered>
           <TextMedium textMedium="We’ve used this template to develop and implement content marketing plans for B2B service and e-commerce companies 
            of various sizes."/> 
            <TextMedium textMedium="As a prerequisite, you should have your brand and marketing strategy already in place, as this
              document doesn't cover the brand and marketing strategy part." />
            <GumroadForm 
                formName="Content Strategy Document"
                redirectPage="https://socontent.gumroad.com/l/content-strategy-b2b"
                buttonText="Get it on Gumroad"
                buttonLink="https://socontent.gumroad.com/l/content-strategy-b2b"
                // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
            />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default ContentStrategyDocument